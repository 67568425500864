import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import * as data from '../../assets/static.json';
import { SessionManageMent } from "./SessionManageMent";
import { SignalR } from "./SignalR";

@Injectable({
  providedIn: 'root'
})

export class WebApiHttp {

  public globalurl: string = data.stagingurl;
  // public globalurl: string = data.url;

  public ApiURLArray: any = {

    // User URL Start
    login: '/api/User/Login',
    Logout: '/api/User/Logout',
    forgetpassword: '/api/UserLogin/ForgotPassword',
    createUser: '/api/User/CreateUser',
    roleProcess: '/api/Role/RoleProcess',
    signalRNotification: '/Notification',
    notificationData: '/api/User/NotificationListData',
    locationlist: '/api/User/LocationList',
    LoginWindowsByToken: '/api/User/LoginWindowsByToken',
    dashboard: '/api/Dashboard/DashboardHeaderGet?location_code=',
    saleDashboard: '/api/Dashboard/DashboardFooterGet?location_code=',
    saleDashboardReport: '/api/CrmReport/sale_order_dashboard_csv_report',
    OBCPTLive: '/api/Dashboard/DashboardSecondGetLogic',
    OBCPTLiveCSV: '/api/Dashboard/DashboardCsvGet',
    InvoiceEfficiency: '/api/Dashboard/dashboard_invoice_efficiency',
    PickingEfficiency: '/api/Dashboard/dashboard_picking_efficiency',
    ConsolidationEfficiency: '/api/Dashboard/dashboard_consolidation_efficiency',
    DispatchRisk: '/api/Dashboard/dashboard_dispatch_at_risk?location_code=',
    DispatchRiskCSV: '/api/Dashboard/dashboard_dispatch_at_risk_csv_get?location_code=',
    approverMaster: '/api/User/InsertApproveMaster',
    // User URL End

    //cluster management
    InsertClusterMaster: '/api/User/InsertClusterMaster',

    // Item URL start
    GetImage: '/api/Item/GetImage?barcode=',
    ItemList: '/api/Item/ItemList',
    ItemCategoryList: '/api/Item/ItemCategoryList',
    ItemCategoryCreate: '/api/Item/ItemCategoryCreate',
    ItemSubCategoryList: '/api/Item/ItemSubCategoryList?code=',
    ItemCategoryDelete: '/api/Item/ItemCategoryDelete',
    ItemFullInfo: '/api/Item/ItemFullInfo',
    // Item URL end

    //PURCHASE ORDER
    GetAllLocationPurchaseOrder: '/api/inbound/Purchase/PurchaseOrder/GetAllLocationPurchaseOrder?location_code=',
    GetPurchaseOrderDetail: '/api/inbound/Purchase/PurchaseOrder/GetPurchaseOrderDetail?purchase_order_no=',
    GetPurchaseOrderByLocationVendor: '/api/inbound/Purchase/PurchaseOrder/GetPurchaseOrderByLocationVendor',
    PurchaseOrderShortClose: '/api/inbound/Purchase/PurchaseOrder/PurchaseOrderShortClose',
    updatePOTime:'/api/inbound/Purchase/PurchaseOrder/POArrivalUpdate',
    //PURCHASE ORDER

    //GATE ENTRY
    GetAllLocationGateEntry: '/api/inbound/Gate/GateEntry/GetAllLocationGateEntry?location_code=',
    GetVendorInfo: '/api/inbound/Gate/GateEntry/GetVendorInfo?location_code=',
    GateEntry_Complete: '/api/inbound/Gate/GateEntry/Complete',

    GateEntry_Detalis: '/api/Item/ItemMstAndComponentGet?item_code=',

    //AQL
    Get_GateEntryDetails: '/api/inbound/AQL/Aql/Get_GateEntryDetails',
    Update_GateEntryDetails: '/api/inbound/AQL/Aql/Update_GateEntryDetails',
    StatusWiseAQL: '/api/inbound/AQL/AQL/AqlGetByStatus?status=',

    //GateOutward
    Update_GateOutward: '/api/inbound/AQL/Aql/Update_GateOutward',

    //PACKING
    packinglist: '/api/inbound/VendorPacking/VendorPacking/packing_list_PO_get?location_code=',
    insertPackingList: '/api/inbound/VendorPacking/VendorPacking/packing_list_insert',
    packingListHeader: '/api/inbound/VendorPacking/VendorPacking/PackingListHeaderGet',
    packingLineGet: '/api/inbound/VendorPacking/VendorPacking/PackingListLineGet',
    PoArrival:'/api/inbound/Purchase/PurchaseOrder/POArrivalList',
    PackingReport:'/api/inbound/VendorPacking/VendorPacking/PackingReport',

    PackingImageUpload:'/api/inbound/VendorPacking/VendorPacking/PackingImageUpload',
    PackinImageDelete:'/api/inbound/VendorPacking/VendorPacking/PackinImageDelete',
    PackingListCancelled:'/api/inbound/VendorPacking/VendorPacking/PackingListCancelled',

    //PACKING

    //IQC
    IQCGetByUser: '/api/inbound/IQC/IQC/IQCGetByUser',
    IQCGetByGrnNo: '/api/inbound/IQC/IQC/IQCGetByGrnNo',
    IQCCreate: '/api/inbound/IQC/IQC/IQCCreate',
    IQCRejectLine: '/api/inbound/IQC/IQC/IQCRejectLine',
    IQCRemoveRejectLine: '/api/inbound/IQC/IQC/IQCRemoveRejectLine',
    IQCComplete: '/api/inbound/IQC/IQC/IQCComplete',
    IQCGrnHeaderGet: '/api/inbound/IQC/IQC/IQCGrnHeaderGet',
    IQCGrnLineGet: '/api/inbound/IQC/IQC/IQCGrnLineGet?grn_no=',
    IQCGrnLineItemSerialGet: '/api/inbound/IQC/IQC/IQCGrnLineItemSerialGet?grn_no=',
    IQCGrnRejectLineSerialGet: '/api/inbound/IQC/IQC/IQCGetByGrnNoBadQty',

    IQCUploadImage: '/api/Item/IQCImageUpload',
    IQCDeleteImage: '/api/Item/IQCImageDelete',

    //Putaway
    GetPutawayGrnDocument: '/api/inbound/Putaway/Putaway/GetPutawayGrnDocument',
    PutawayHeaderCreate: '/api/inbound/Putaway/Putaway/PutawayHeaderCreate',
    PutawayBoxSerialScan: '/api/inbound/Putaway/Putaway/PutawayBoxSerialScan',
    PutwayDeleteDocument: '/api/inbound/Putaway/Putaway/PutwayDeleteDocument',
    PutwayComplete: '/api/inbound/Putaway/Putaway/PutwayComplete',
    PutawayReleaseBin: '/api/inbound/Putaway/Putaway/PutawayReleaseBin',
    PutawayShiftToAllItemQty: '/api/inbound/Putaway/Putaway/PutawayShiftToAllItemQty',
    PutawayHeaderGet: '/api/inbound/Putaway/Putaway/PutawayHeaderGet',
    PutawayLineGet: '/api/inbound/Putaway/Putaway/PutawayLineGet?putaway_no=',
    PutawayItemBinSuggestion: '/api/inbound/Putaway/Putaway/PutawayItemBinSuggestion',

    //Adjustment
    createAdjustmentHeader: '/api/inbound/Putaway/Adjustment/AdjustmentHeaderCreate',
    uploadAdjScan: '/api/inbound/Putaway/Adjustment/AdjustmentBoxSerialScan',
    DiscardAllAdjLines: '/api/inbound/Putaway/Adjustment/DiscardAllLines',

    getAdjLines: '/api/inbound/Putaway/Adjustment/AdjustmentApprovePendinglist',

    completeAdjDoc: '/api/inbound/Putaway/Adjustment/AdjustmentComplete',
    adjVendorSerialDets: '/api/inbound/Putaway/Adjustment/AdjustmentItemLotList',
    adjApproveReject: '/api/inbound/Putaway/Adjustment/AdjustmentApprovedComplete',
    adjRejected: '/api/inbound/Putaway/Adjustment/AdjustmentReOpen',
    //returnPutaway
    ReturnGetPutawayGrnDocument: '/api/returns/ReturnPutaway/ReturnGetPutawayGrnDocument',
    ReturnPutawayHeaderCreate: '/api/returns/ReturnPutaway/ReturnPutawayHeaderCreate',
    ReturnPutawayBoxSerialScan: '/api/returns/ReturnPutaway/ReturnPutawayBoxSerialScan',
    ReturnPutwayDeleteDocument: '/api/returns/ReturnPutaway/ReturnPutwayDeleteDocument',
    ReturnPutwayComplete: '/api/returns/ReturnPutaway/ReturnPutwayComplete',
    ReturnPutawayReleaseBin: '/api/returns/ReturnPutaway/ReturnPutawayReleaseBin',
    ReturnPutawayShiftToAllItemQty: '/api/returns/ReturnPutaway/ReturnPutawayShiftToAllItemQty',

    //todo serialpopup

    getSerial:'/api/inbound/Putaway/Putaway/GetGrnSerial?flag=',

    // Ledger URL start

    ItemLedgerList: '/api/Item/ItemInventoryLedgerGet',
    ExcelItemLedgerList: '/api/Item/ItemInventoryLedgerReportGet',

    // Lager URL end

    //todo GRN Data

    GRN_GetGRNList: '/api/inbound/GRN/GRN/GetGRNList',
    GRN_GateEntryWisePOGet: '/api/inbound/GRN/GRN/GateEntryWisePOGet',
    GRNHeaderCreateList: '/api/inbound/GRN/GRN/GRNHeaderCreateList',
    GRN_GrnLineUpload: '/api/inbound/GRN/GRN/GrnLineUpload',
    GRN_GrnHeaderStatusChange: '/api/inbound/GRN/GRN/GrnHeaderStatusChange',
    GrnDistributionData: '/api/inbound/GRN/GRN/GrnDistributionData',
    GRNDistributionInsert: '/api/inbound/GRN/GRN/GRNDistributionInsert',
    GRNDistributionDeleteLine: '/api/inbound/GRN/GRN/GRNDistributionDeleteLine',
    GrnHeaderDiscard: '/api/inbound/GRN/GRN/GrnHeaderDiscard?grn_no=',
    GrnLineBoxPackingGet: '/api/inbound/GRN/GRN/GrnLineBoxPackingGet',
    GrnLineBoxPackingCreate: '/api/inbound/GRN/GRN/GrnLineBoxPackingCreate',
    GrnLineBoxDiscard: '/api/inbound/GRN/GRN/GrnLineBoxDiscard',
    GrnComplete: '/api/inbound/GRN/GRN/GrnComplete',

    GRN_BoxReport: '/api/Reports/GRN_BoxReport?grn_line_distribution_id=',
    GRN_SerialReport: '/api/Reports/GRN_SerialReport?grn_line_distribution_id=',

    GRN_GrnLineDownload: '/api/inbound/GRN/GRN/GrnLineDownload?grn_no=',
    GRN_GrnLineUploadActual: '/api/inbound/GRN/GRN/GrnLineUploadActual',

    GrnPurchasePackingGetByGateEntry: '/api/inbound/GRN/GRN/GrnPurchasePackingGetByGateEntry',
    GrnVendorPackingScanSerialBox: '/api/inbound/GRN/GRN/GrnVendorPackingScanSerialBox',
    grn_purchase_order_BoxReport:'/api/Reports/grn_purchase_order_BoxReport',

    //todo pick work
    PickGetInfo: '/api/outbound/pickcreation/PickCreation/PickGetInfo',
    Pick_GetOB_CPTDropdown: '/api/outbound/pickcreation/PickCreation/GetOB_CPTDropdown?location_code=',
    GetShipingProviderName: '/api/outbound/pickcreation/PickCreation/GetShipingProviderName?location_code=',
    Pick_GetOrdersCategories: '/api/outbound/pickcreation/PickCreation/GetOrdersCategories',
    Pick_GetTotalOrders: '/api/outbound/pickcreation/PickCreation/GetTotalOrders',
    PickCreateComplete: '/api/outbound/pickcreation/PickCreation/PickCreateComplete',
    PickPriorityUpdate: '/api/outbound/pickcreation/PickCreation/PickPriorityUpdate',
    PickCheckTransfer: '/api/outbound/pickcreation/PickCreation/PickCheckTransferPendingLines',
    PickMarketPlace: '/api/outbound/pickcreation/PickCreation/GetMarketPlaceMaster?type_of_order=',
    PickCheckSale: '/api/outbound/pickcreation/PickCreation/PickCheckSalePendingLines',
    getShipmentOrder:'/api/Marketplace/GetVinculumOrderListCarrierNotUpdated?location_id=',

    GetPickDistribution:'/api/outbound/pickcreation/PickCreation/GetPickDistribution',
    PickDistributionManualDelete:'/api/outbound/pickcreation/PickCreation/PickDistributionManualDelete',

    PickDistributionAutoCreate:'/api/outbound/pickcreation/PickCreation/PickDistributionAutoCreate',


    PickDistributionZone:'/api/outbound/pickcreation/PickCreation/PickDistributionZone',
    GetPickerId:'/api/outbound/pickcreation/PickCreation/GetPickerId',
    GetBincodeRange:'/api/outbound/pickcreation/pickCreation/GetBincodeRange',

    PickDistributionManualCreate:'/api/outbound/pickcreation/PickCreation/PickDistributionManualCreate',


    //todo pick work
    RePickGetInfo: '/api/outbound/repickcreation/RepickCreation/PickGetInfo',
    RePick_GetOB_CPTDropdown: '/api/outbound/repickcreation/RepickCreation/GetOB_CPTDropdown?location_code=',
    RePickGetShipingProviderName: '/api/outbound/repickcreation/RepickCreation/GetShipingProviderName?location_code=',
    RePick_GetTotalOrders: '/api/outbound/repickcreation/RepickCreation/GetTotalOrders',
    RePickCreateComplete: '/api/outbound/repickcreation/RepickCreation/PickCreateComplete',
    PickScanLooptag:'/api/outbound/Pick/PickScanLooptagOnWeb',

    // dashboard
    pos_dash_sale_amount: '/pos/api/Dashboard/dash_sale_amount/',
    pos_dash_weekly_sale_amount: '/pos/api/Dashboard/dash_weekly_sale_amount/',
    dash_sale_amount_data_from_to: '/pos/api/Dashboard/dash_sale_amount_data_from_to',
    // Setup

    RoleMasterProcess: '/api/Role/RoleProcess',
    RolePermissionDetail: '/api/Role/RolePermissionDetail/',
    RolePermissionUpdate: '/api/Role/RolePermissionUpdate',

    // UserSetup

    GetAllUser: '/api/User/allUser',
    CreateUser: '/api/User/createUser',
    UpdateUser: '/api/User/updateUser',
    UpdateUserPassword: '/api/User/UpdatePassword',
    AddPrinterIPaddress: '/api/User/AddIPandPort',

    // Auto Printing
    getPrintList:'/api/AutoPrinting/GetAutoPrintingList',
    deletePrint:'/api/AutoPrinting/AutoPrintingDelete?id=',
    softwareList:'/api/AutoPrinting/GetAutoPrintingSoftwareList?software=',
    processList:'/api/AutoPrinting/GetAutoPrintingProcessTypeList?software=',
    documentlist:'/api/AutoPrinting/GetAutoPrintingDocumnetPageList?software=',
    printerList:'/api/AutoPrinting/GetAutoPrintingMst?software=',
    createAutoPrint:'/api/AutoPrinting/AutoPrintingCreate',

    //  Order Pull Requests

    OrderPullRequests:'/api/SaleOrder/getSaleOrderFromEasyEcom',
    DetailPullRequests:'/api/SaleOrder/getSaleOrderDetailFromEasyEcom',
    SplitPullRequests:'/api/SaleOrder/getSaleOrderDetailFromEasyEcomDuplicateOrderCreate',
    CancelPullRequests:'/api/SaleOrder/saleOrderCancelFromEasyEcom',

    //force dispatched
    UploadForceDispatched:'/api/ForceDispatch/ForceDispatchUpload',
    GetForceDispatchedData:'/api/ForceDispatch/GetForceDispatchHeaderData',
    GetForceLineData:'/api/ForceDispatch/ForceDispatchLinesGet',
    HitForceDispatch:'/api/ForceDispatch/ForceDispatchVinculumOrderProcess',
    CompleteForceDispatchLine:'/api/ForceDispatch/ForceDispatchHeaderLineDataGet',
    ForceDispatchCancelationLogGet:'/api/ForceDispatch/ForceDispatchCancelationLogGet?force_dispatched_no=',
    ForceDispatchUnderProcessingOrderGet:'/api/ForceDispatch/ForceDispatchUnderProcessingOrderGet',
    ForceDispatchCheckCancelationHit:'/api/ForceDispatch/ForceDispatchCheckCancelationHit',
    ForceDispatchedHeaderDelete:'/api/ForceDispatch/ForceDispatchedHeaderDelete',
    ForceDispatchedLineOrderWiseDelete:'/api/ForceDispatch/ForceDispatchedLineOrderWiseDelete',

    OrderPullRequestsVinculum:'/api/SaleOrder/getSaleOrderFromVinculum',
    ShipmentPullRequests:'/api/SaleOrder/getSaleOrderShippingDetailFromVinculum',
    ShipmentPullRequestsWithOrder:'/api/SaleOrder/getSaleOrderOrderNoShippingDetailFromVinculum',
    CancelPullRequestsVinculum:'/api/SaleOrder/getSaleOrderCancelFromVinculum',

    //transfer order
    InboundList: '/api/TransferOrder/InboundList',
    AddNewItem: '/api/TransferOrder/ScanBarcodeSerialByUser',
    deleteBarcodeSerialByUser: '/api/TransferOrder/deleteBarcodeSerialByUser',
    DiscardTransferOrderDocument: '/api/TransferOrder/DiscardTransferOrderDocument',
    TransferOrderNavInsert: '/api/TransferOrder/TransferOrderNavInsert',
    NewTransferOrderHeader: '/api/TransferOrder/NewTransferOrderHeader',
    CompleteTransfer: '/api/TransferOrder/Complete',
    TransferOrderReport: '/api/TransferOrder/TransferOrderReport?transfer_no=',
    TransferOrderInfo: '/api/TransferOrder/TransferOrderInfo',
    TansferOrderReceivedScanBarcode: '/api/TransferOrder/TansferOrderReceivedScanBarcode',
    TansferOrderReceivedComplete: '/api/TransferOrder/TansferOrderReceivedComplete',
    ManualTORecive: '/api/transfer/TransferOrder/ManualTORecive',
    cancelTO: '/api/transfer/TransferOrder/CancelTransfer_RGP_Purchase_Order',
    //end

    //todo einvoice work
    einvoice_generateIRN: '/api/Einvoice/generateIRN',
    einvoice_CancelIRN: '/api/Einvoice/CancelIRN',
    einvoice_generateEWayBill: '/api/Einvoice/generateEWayBill',
    einvoice_CancelEWayBill: '/api/Einvoice/CancelEWayBill',
    einvoice_GetEinvoiceReport: '/api/Einvoice/GetEinvoiceReport?document_no=',
    einvoice_GetTranspoter: '/api/Einvoice/GetTranspoter',

    //Reports
    sale_category_report: '/api/Reports/sale_category_report',
    sale_dashboard: '/api/Reports/sale_dashboard',
    customer_wise_report: '/api/Reports/customer_wise_report',
    sale_person_wise_report: '/api/Reports/sale_person_wise_report',
    detailed_sale_report: '/api/Reports/detailed_sale_report',
    get_sale_person_payment_report: '/api/Reports/get_sale_person_payment_report',
    pos_get_store_Wise_Sale_Report: '/api/Reports/pos_get_store_Wise_Sale_Report',
    get_sale_category_contribution_report: '/api/Reports/pos_get_Category_Wise_Report',
    get_store_inventory_report: '/api/Reports/pos_get_Inventory_Report?store_id=',
    transfer_order_detail_report: '/api/Reports/transfer_order_report',
    credit_note_report: '/api/Reports/credit_note_report',
    adjustment_detail_report: '/api/Reports/adjustment_report',
    cycle_count_report: '/api/Reports/cycle_count_report',
    //adjustment

    DocumentList: '/api/ItemAdjustment/DocumentList',
    DocumentCreate: '/api/ItemAdjustment/DocumentCreate',
    DocumentView: '/api/ItemAdjustment/DocumentView',
    AdjustmentWithoutScan: '/api/ItemAdjustment/AdjustmentWithoutScan',
    AdjustmentWithUpload: '/api/ItemAdjustment/AdjustmentWithUpload',
    DeleteLine: '/api/ItemAdjustment/DeleteLine',
    AdjustmentComplete: '/api/ItemAdjustment/Complete',
    ApprovalComplete: '/api/ItemAdjustment/ApprovalComplete',


    //Return  Grn
    ReturnGRNCreateHeader: '/api/returns/ReturnGRN/ReturnGRNCreateHeader',
    ReturnGRNDiscard: '/api/returns/ReturnGRN/ReturnGRNDiscard',
    ReturnGRNScanBarcode: '/api/returns/ReturnGRN/ReturnGRNScanBarcode',
    RetunGRNDeleteLine: '/api/returns/ReturnGRN/RetunGRNDeleteLine',
    ReturnGRNComplete: '/api/returns/ReturnGRN/ReturnGRNComplete',
    ReturnGRNInvoiceDetail: '/api/returns/ReturnGRN/ReturnGRNInvoiceDetail',
    ReturnGRnReport: '/api/returns/ReturnGRN/ReturnInvoiceReport?grn_no=',
    SerialReport: '/api/Reports/SerialReport?loc_code=',
    SerialReportNonBeauty: '/api/Reports/SerialReportNonBeauty?loc_code=',
    BoxReport: '/api/Reports/BoxReport?loc_code=',
    ReturnGrnClaimCreate: '/api/returns/ReturnGRN/ReturnGrnClaimCreate',
    ReturnGRNGenerateBarcodeB2BSale: '/api/returns/ReturnGRN/ReturnGRNGenerateBarcodeB2BSale',
    SyncData: '/api/returns/ReturnGRN/B2CReturnSync',
    duplicateSerial:'/api/Reports/SerialReportNew?loc_code=',


    GetReturnRequestUploaderOrderData: '/api/returns/ReturnGRN/GetReturnRequestUploaderOrderData',
    getInvoiceOrderData: '/api/returns/ReturnGRN/getInvoiceOrderData',
    Return_request_scan_order: '/api/returns/ReturnGRN/Return_request_scan_order',
    Return_request_scan_barcode: '/api/returns/ReturnGRN/Return_request_scan_barcode',
    getReturnGrnLineDataSerial: '/api/returns/ReturnGRN/getReturnGrnLineDataSerial',
    Return_request_discard_document: '/api/returns/ReturnGRN/Return_request_discard_document',

    //b2b return
    ReturnGRNCreateHeaderB2BSale: '/api/returns/ReturnGRN/ReturnGRNCreateHeaderB2BSale',
    RetunGRNScanBarcodeB2bSale: '/api/returns/ReturnGRN/RetunGRNScanBarcodeB2bSale',

    ManifestImageUpload: '/api/Item/ManifestImageUpload',
    ManifestImageDelete: '/api/Item/ManifestImageDelete',

    InvoiceImageUpload: '/api/Item/InvoiceImageUpload',
    InvoiceImageDelete: '/api/Item/InvoiceImageDelete',

    ReturnImageUpload: '/api/Item/ReturnImageUpload',
    ReturnImageDelete: '/api/Item/ReturnImageDelete',

    //Partial
    PartialOrderList: '/api/outbound/Partial/PartialOrderList?location_code=',


    //todo POS Apis
    //product
    pos_all_brand: '/pos/api/Brand/all_brand',
    pos_create_Brand: '/pos/api/Brand/create_Brand',
    pos_delete_Brand: '/pos/api/Brand/delete_Brand',
    //end
    //cusstomer
    pos_all_customer: '/pos/api/Customer/all_customer',
    pos_new_customer: '/pos/api/Customer/new_customer',
    pos_find_customer: '/pos/api/Customer/find_customer',
    pos_get_customer_cust_id: '/pos/api/Customer/get_customer_cust_id/',
    GetCustomerHistory: '/pos/api/Customer/GetCustomerHistory?customer_id=',

    //sale process
    getSalePrice: '/pos/api/Sale/getprice',
    pos_check_open_sale: '/pos/api/Sale/check_open_sale',
    pos_search_product: '/pos/api/Sale/search_product',
    UpdateCustomerOnSaleHeader: '/pos/api/Sale/UpdateCustomerOnSaleHeader',
    pos_sale_barcode_scan: '/pos/api/Sale/sale_barcode_scan',
    pos_SaleCashbackSync: '/pos/api/Sale/SaleCashbackSync',
    sale_apply_coupon_scan: '/pos/api/Sale/sale_apply_coupon_scan',
    pos_sale_barcode_delete: '/pos/api/Sale/sale_barcode_delete',
    pos_sale_order_post: '/pos/api/Sale/order_post',
    pos_discard_or_park: '/pos/api/Sale/discard_or_park',
    get_all_invoice_sale_ho: '/pos/api/Sale/get_all_invoice_sale_ho',
    get_sale_order_for_hold: '/pos/api/Sale/get_sale_order_for_hold',
    sale_line_price_discount_change: '/pos/api/Sale/sale_line_price_discount_change',
    SaleInvoiceReport: '/pos/api/sale/SaleInvoiceReport?invoice_no=',
    ReturnInvoiceReport: '/pos/api/Return/ReturnInvoiceReport?return_invoice_no=',
    get_sale_person_detail: '/pos/api/SalePerson/get_sale_person_detail?location_id=',
    sale_person_create: '/pos/api/SalePerson/sale_person_create',
    sale_person_payment: '/pos/api/sale/sale_person_payment',
    get_sale_person_commission_by_date: '/pos/api/sale/get_sale_person_commission_by_date',
    pos_find_saleperson: '/pos/api/SalePerson/find_saleperson',
    get_search_CreditNotDetail: '/pos/api/sale/get_search_CreditNotDetail',
    Sale_get_CreditNotDetail: '/pos/api/Sale/get_CreditNotDetail',
    GetAllSaleRange: '/pos/api/Sale/GetAllSaleRange',


    //return order
    return_barcode_delete: '/pos/api/Return/return_barcode_delete',
    delete_reurn_temp_sale_order_data: '/pos/api/Return/delete_reurn_temp_sale_order_data?sale_order_no=',
    pos_return_post: '/pos/api/Return/return_post',
    getSaleRecord: '/pos/api/Return/get_sale_return_order',
    refundItem: '/pos/api/Return/create_return_sale',
    pos_get_sale_details: '/pos/api/Sale/get_sale_details/',
    get_credit_note_header: '/pos/api/Return/get_credit_note_header',
    get_credit_note_detail: '/pos/api/Return/get_credit_note_detail/',
    pos_GetStoreLocationList: '/api/Location/GetLocationList?email_id=',
    pos_InsertStoreLocationList: '/api/Location/InsertLocationList',
    GetSyncProcessGetData: '/api/NavSync/SyncProcessGetData?flag=',
    NavPostSyncProcess: '/api/NavSync/SyncProcessDataHitNav',

    GetDataEasyEcom: '/api/NavSync/SyncProcessGetDataEasyEcom?flag=',
    GetDataVinculum: '/api/NavSync/SyncProcessGetDataVinculum?flag=',

    //price group
    pos_InsertUpdateSelectPriceGroup: '/api/Location/InsertUpdateSelectPriceGroup',
    pos_InsertUpdateSelectDeletePOSTerminalMaster: '/api/Location/InsertUpdateSelectDeletePOSTerminalMaster',
    InsertUpdatePOSPriceMaster: '/api/Location/InsertUpdatePOSPriceMaster',

    // end

    //todo state and district apis
    GetStateName: '/api/Location/GetStateName',
    GetStateList: '/api/Location/GetStateList',
    GetDistrictName: '/api/Location/GetDistrictName?state_code=',

    //todo cycle count
    GetCycleCountCompletedListData: '/api/internal/CycleCounting/CycleCount/GetCycleCountCompletedListData',
    CycleCountApproveComplete: '/api/internal/CycleCounting/CycleCount/CycleCountApproveComplete',

    CycleCountApprovePendinglist: '/api/internal/CycleCounting/CycleCount/CycleCountApprovePendinglist',

    GetCycleCountDetails: '/api/internal/CycleCounting/CycleCount/GetCycleCountDetails',
    GetCycleCountItemSerial: '/api/internal/CycleCounting/CycleCount/GetCycleCountItemSerial',
    GetCycleCountSerial: '/api/internal/CycleCounting/CycleCount/GetCycleCountSerial',
    GetCycleCountSystemSerial: '/api/internal/CycleCounting/CycleCount/GetCycleCountSystemSerial',
    CycleCountScanFromBincode: '/api/internal/CycleCounting/CycleCount/CycleCountScanFromBincode',
    CycleCountHeaderDelete: '/api/internal/CycleCounting/CycleCount/CycleCountHeaderDelete',
    CycleCountScanSerialBox: '/api/internal/CycleCounting/CycleCount/CycleCountScanSerialBox',
    CycleCountComplete: '/api/internal/CycleCounting/CycleCount/CycleCountComplete',

    //todo pos cycle count

    GetCycleCountHeader: '/pos/api/CycleCount/GetCycleCountHeader',
    ScanCycleCountItem: '/pos/api/CycleCount/ScanCycleCountItem',
    DeleteCycleCountItem: '/pos/api/CycleCount/DeleteCycleCountItem',
    GetItemInventoryList: '/pos/api/CycleCount/GetItemInventoryList?item_no=',
    CompleteCycleCountItem: '/pos/api/CycleCount/CompleteCycleCountItem',
    GetCycleCountList: '/pos/api/CycleCount/GetCycleCountList',
    ApproveGetCycleCountList: '/pos/api/CycleCount/ApproveGetCycleCountList',
    ApproveCycleCountBarcodeQtyChange: '/pos/api/CycleCount/ApproveCycleCountBarcodeQtyChange',
    ApproveCycleCountComplete: '/pos/api/CycleCount/ApproveCycleCountComplete',
    RejectCycleCountComplete: '/pos/api/CycleCount/RejectCycleCountComplete',
    DeleteCycleCountDocument: '/pos/api/CycleCount/DeleteCycleCountDocument',
    //todo end


    get_inventory_report: '/api/Reports/get_inventory_report',
    get_stock_inventory_report: '/api/Reports/get_pos_stock_ledger_report',
    get_storewise_inventory_report: '/api/Reports/get_pos_store_wise_inventory_report',
    get_item_without_store: '/pos/api/Sale/search_product_without_storeid',

    //todo day close
    StatementPostingGetDateRange: '/api/StatementPosting/StatementPostingGetDateRange',
    StatementCreateHeader: '/api/StatementPosting/StatementCreateHeader',
    StatementUpdateLineAmount: '/api/StatementPosting/StatementUpdateLineAmount',
    StatementPostingDiscardDocument: '/api/StatementPosting/StatementPostingDiscardDocument',
    StatementPostingReport: '/api/StatementPosting/StatementPostingReport',

    //todo Masters
    GetBrandMst: '/api/ItemAttribute/BrandMstGet',
    GetStyleMst: '/api/ItemAttribute/StyleMstGet',
    GetColorMst: '/api/ItemAttribute/ColorMstGet',
    GetManufacturerMst: '/api/ItemAttribute/ManufacturerMstGet',
    GetCategoryMst: '/api/ItemAttribute/CategoryMstGet?location_code=',
    GetProductGroupMst: '/api/ItemAttribute/ProductGroupMstGet',
    GetSubProductGroupMst: '/api/ItemAttribute/SubProductGroupMstGet',
    GetAttributeMst: '/api/ItemAttribute/AttributeMstGet?category_code=',
    GetGstHsnMst: '/api/Item/GstHsnMstGet',
    GetGstSetupMst: '/api/Item/GStSetupGet',
    CategoryZoneRelationInsert: '/api/ItemAttribute/CategoryZoneRelationInsert',
    GetCategoryWithZone: '/api/ItemAttribute/GetCategoryWithZone',

    SafetyStockUploader: '/api/Item/InsertItemInventorySafetyStock',
    GetSafetyItem: '/api/Item/GetItemInventorySafetyStock',


    // todo MarketPlace
    getmarketlist:'/api/Marketplace/GetMarketplaceList?type_of_order=',
    CreateMarketPlace:'/api/Marketplace/MarketplaceCreate',
    GetMarket:'/api/Marketplace/GetApiList',
    GetMarketCustomerList:'/api/Marketplace/GetMarketplaceCustomersList',
    CreateMarketCustomer:'/api/Marketplace/MarketplaceCustomerCreate',
    UploadMarketAutoPrinting:'/api/Marketplace/MarketplaceIsAutoPrintingUploader',
    MarketPlaceList:'/api/Marketplace/GetMarketplaceListNew',
    CategoryLoopTagGet:'/api/Marketplace/CategoryLooptagMstGet',
    CategoryLoopInsert:'/api/Marketplace/CategoryLooptagMstInsert',
    CategoryLoopUpdate:'​/api/Marketplace/CategoryLooptagMstUpdate',
    CategoryLoopDelete:'/api/Marketplace/CategoryLooptagMstDelete',
    SaleOrderCancellationLog:'/api/ForceDispatch/SaleOrderStatusHitCancellationLogGet?order_no=',

    //PackingMaterial
    PackingMaterialGet: '/api/PackingMaterial/PackingMaterialGetModel',
    PackingMaterialInsert: '/api/PackingMaterial/PackingMaterialInsertModel',
    PackingMaterialUploader: '/api/PackingMaterial/PackingMaterialUploaderModel',
    PackingMaterialUpdate: '/api/PackingMaterial/PackingMaterialUpdateModel',
    PackingMaterialDelete: '/api/PackingMaterial/PackingMaterialDeleteModel',

    ItemMarketPlaceListing: '/api/Item/ItemMarketPlaceListingGet',

    //Roster
    createRoster: '/api/distribution/Roster/CreateRoster',
    ManagerDataRoster: '/api/distribution/Roster/ManagerData?location_code=',
    allRoster: '/api/distribution/Roster/AllRoster?Email=',
    pickZone: '/api/outbound/PickZone/GetPickZone',

    //Shift
    deleteShift: '/api/distribution/Shift/DeleteShift?location_code=',
    createShift: '/api/distribution/Shift/CreateShift',
    updateShift: '/api/distribution/Shift/UpdateShift',
    allShift: '/api/distribution/Shift/AllShift?location_code=',

    //Location
    locationList: '/api/Location/GetLocationList',

    //Bin
    GetBinMst: '/api/BinMst/BinMstGet',
    GetBinReport: '/api/BinMst/BinBarcodeReport',
    InsertBinMst: '/api/BinMst/BinMstInsert',
    UpdateBinMst: '/api/BinMst/BinMstUpdate',
    DeleteBinMst: '/api/BinMst/BinMstDelete',
    GetBinType: '/api/BinMst/BinTypeGet',

    //rejection
    Rejection: '/api/RejectionMst/RejectionAllinOne',

    //item
    GetItemMst: '/api/Item/ItemMstGet',
    GetItemInventory: '/api/inventory/ItemInventory/ItemInventoryGet',
    GetItemBinInventory: '/api/inventory/ItemInventory/ItemBinInventoryGet',
    GetItemBinSerialInventory: '/api/inventory/ItemInventory/ItemSerialInventoryGet',

    //sale order
    getSaleOrderHeader: '/api/SaleOrder/GetSaleOrderHeaderList',
    getSaleOrderDetails: '/api/SaleOrder/SaleOrderGet?sale_order_id=',
    getInvoiceHeaderDetails: '/api/SaleOrder/InvoiceHeaderGet',
    getInvoiceHeaderLines: '/api/SaleOrder/InvoicedReportGet',
    getInvoicePackagingMaterial: '/api/SaleOrder/sale_order_packing_get',
    getSaleOrderCustomerHeader: '/api/SaleOrder/GetSaleOrderCustomerMstWeb',
    getSaleOrderAddressMaster: '/api/SaleOrder/GetAddressrMstWeb',
    downloadSaleOrderInvoice: '/api/outbound/OutboundQualityCheck/Report?order_no=',
    dowlnoadDeliveryChallan: '/api/outbound/OutboundQualityCheck/ChallanReport?sale_no=',
    getSaleOrderStatus: '/api/SaleOrder/order_status_activity_get?location_code=',
    dcSingleReport: '/api/outbound/OutboundQualityCheck/DCSingleReportDownload_url?order_no=',
    getSaleOrderHeaderRepickReadyList: '/api/SaleOrder/GetSaleOrderHeaderRepickReadyList?location_code=',
    saleOrderCancelRepick: '/api/SaleOrder/SaleOrderCancelRepick',
    SaleOrderMarkDispatchB2B: '/api/outbound/OutboundQualityCheck/SaleOrderMarkDispatchB2B',
    getSaleShipDetailFromVinculum: '/api/SaleOrder/getSaleOrderOrderNoShippingDetailFromVinculum',
    saleOrderMarkShipVinculum: '/api/outbound/OutboundQualityCheck/SaleOrderMarkShipVinculum',

    // sale-dsp-uploader

    getSaleShippingProvider:'/api/SaleOrder/SaleShippingProviderHeaderGet',
    UploadSaleShippingProvider:'/api/SaleOrder/SaleShippingProviderUploader',
    SaleShippingLineGet:'/api/SaleOrder/SaleShippingProvideUploaderGet?location_code=',
    //IRN Eway bill
    generateIRN: '/api/outbound/IRNEwayBill/GenerateIRN',
    cancelIRN: '/api/outbound/IRNEwayBill/CancelIRN',
    cancelEWAY: '/api/outbound/IRNEwayBill/CancelEwayBill',
    getIRNcancelReason: '/api/outbound/IRNEwayBill/EInvoiceIRNCancelMstGet',
    getEwaycancelReason:'/api/outbound/IRNEwayBill/EInvoiceEwayBillCancelMstGet',
    generateEwayBill: '/api/outbound/IRNEwayBill/EwayBillGenerate',
    getModefTravel: '/api/outbound/IRNEwayBill/ModeOfTravelMstGet',

    //EInvoice Gst Mst
    EInvoiceGstRegistrationGet: '/api/outbound/IRNEwayBill/EInvoiceGstRegistrationGet',
    EInvoiceGstRegistrationUpdate: '/api/outbound/IRNEwayBill/EInvoiceGstRegistrationUpdate',

    // PickZone
    getPickZone: '/api/outbound/PickZone/PickZoneGet',
    insertPickZone: '/api/outbound/PickZone/PickZoneInsert',
    updatePickZone: '/api/outbound/PickZone/PickZoneUpdate',

    getDspPartner: '/api/outbound/DspPartner/DspPartnerMstGet?location_code=',
    deleteDspPartner: '/api/outbound/DspPartner/DspPartnerMstDelete?location_code=',
    insertDspPartner: '/api/outbound/DspPartner/DspPartnerMstInsert',
    getDSPList: '/api/outbound/DspPartner/ShippingProviderName',

    getDspAwbPartner: '/api/outbound/DspPartner/DspAwbMstGet',
    deleteDspAwbPartner: '/api/outbound/DspPartner/DspAwbMstDelete?awb_no=',
    insertDspAwbPartner: '/api/outbound/DspPartner/DspAwbMstInsert',

    locationMStRelation: '/api/Location/LocationRelationMst_GET',
    locationMStRelationUpdate: '/api/Location/LocationRelationMst_Update',

    slot_Report: '/api/OutboundReport/SlotInfo',
    Slotinfo_report: '/api/OutboundReport/SlotSingleReport?location_code=',
    PickInfoReport: '/api/OutboundReport/PickInfo',
    unHoldOrder: '/api/OutboundReport/UnholdOrder?loc_code=',
    SlotForceReleased:'/api/outbound/Sorting/ConsolidationSlotForceReleased',
    SlotReallocate:'/api/outbound/Sorting/ConsolidationForceSlotAssignedToNewSlot',

    //Item Inventory
    item_inventory_vinculum_uploader: '/api/Item/item_inventory_vinculum_uploader',
    GetItemInventoryVinculumUploaderHeaderData: '/api/Item/GetItemInventoryVinculumUploaderHeaderData',
    //Item Inventory

    //multi OQC
    getZone: '/api/outbound/Sorting/ZoneList?location_code=',
    getOQC: '/api/outbound/OutboundQualityCheck/GetSlotForOQC',
    rangeCheck: '/api/outbound/OutboundQualityCheck/RangeChange',
    scanPackaging: '/api/outbound/OutboundQualityCheck/ScanPackingMaterial?packing_material=',
    releaseHold: '/api/outbound/OutboundQualityCheck/ReleaseHold',
    postOQC: '/api/outbound/OutboundQualityCheck/PostOQC',
    postB2BOQC: '/api/outbound/OutboundQualityCheck/Post_OQC_B2B',
    postB2BOQCTO: '/api/outbound/OutboundQualityCheck/Post_OQC_B2B_To',
    multiInvoice: '/api/outbound/OutboundQualityCheck/CreateMultiInvoice',
    OQCCancelOrder: '/api/outbound/OutboundQualityCheck/OQCCancelOrder',

    //single oqc
    singlePostOqc:'/api/outbound/OutboundQualityCheck/GetOrderForSinglePostOQC',
    Postoqc:'/api/outbound/OutboundQualityCheck/SinglePostOQC',
    SinglePostHit:'/api/outbound/OutboundQualityCheck/SingleOrderConsolidation',

    // consolidatation logs

    getConsolidationLogs:'/api/SaleOrderConsolidationLogs/getSaleOrderConsolidationLogs',
    VinculumSingleHit:'/api/outbound/OutboundQualityCheck/SingleOrderConsolidation',
    GetFile:'/api/outbound/OutboundQualityCheck/GetFile',
    GetInvoice:'/api/outbound/OutboundQualityCheck/InvoiceReport?sale_no=',
    GetChallan:'/api/outbound/OutboundQualityCheck/ChallanReport?sale_no=',

    // //Shipping Invoice
    getInvoiceCourier: '/api/outbound/InvoiceReshipment/GetInvoiceCourierData',
    getInvoiceReadyToShip: '/api/outbound/InvoiceReshipment/GetInvoiceReadyToShipData',
    invoiceShippingProviderChange: '/api/outbound/InvoiceReshipment/invoiceShippingProviderChange',
    reshipInvoice: '/api/outbound/InvoiceReshipment/invoiceReship',

    //CRM Reports
    GrnReport: '/api/CrmReport/GrnReportCsvLink',
    PilckListReport: '/api/CrmReport/PilckListCsvLink',
    GateEntryReport: '/api/CrmReport/GateEntryCsvLink',
    ItemMasterReport: '/api/CrmReport/ItemMstCsvLink',
    ShippingPackReport: '/api/CrmReport/ShippingPackageCsvLink',
    SaleOrderDayWiseReport: '/api/CrmReport/SaleOrderDayWiseCsvLink',
    SaleOrderReport: '/api/CrmReport/SaleOrderReportCsvLink',
    ItemInventoryReportGet: '/api/CrmReport/ItemInventoryCsvLink',
    ItemBinInventoryReportGet: '/api/CrmReport/ItemBinInventoryCsvLink',
    ItemSerialInventoryReportGet: '/api/CrmReport/ItemSerialInventoryCsvLink',
    ReturnRequestReportGet: '/api/CrmReport/ReturnRequestReportCsvLink',
    DaywiseSaleReturnReportGet: '/api/CrmReport/DaywiseSaleReturnCsvLink',
    TillDateItemInventoryReportGet: '/api/CrmReport/till_date_item_inventory_CsvLink',
    TillDateItemSerialReportGet: '/api/CrmReport/till_date_item_serail_report_CsvLink',
    TransferOrderCsvReport: '/api/CrmReport/TransferOrderCsvLink',
    OpeningClosingInventoryReport: '/api/CrmReport/opening_closing_inventory_ledger_CsvLink',
    PutawayPendingReport: '/api/CrmReport/putaway_pending_report_CsvLink',
    AdjustmentReport: '/api/CrmReport/adjustment_report_CsvLink',
    ItemInventoryLedgerReport: '/api/CrmReport/ItemInventoryLedgerReportCsvLink',
    CycleCountCsvReport:'/api/CrmReport/CycleCountReportCsvLink',
    ReturnCrCsvReport:'/api/CrmReport/ReturnCRReportCsvLink',
    GrnCustomerSerial:'/api/CrmReport/RetrunGRNCustomerSerialReportCsvLink',
    returnGrnCutomerreport:'/api/Reports/ReturnGrnSerialCustomerReport',
    ItemSafetyStock:'/api/CrmReport/ItemInventorySafetyStockReportCsvLink',
    B2BtransferReport:'/api/CrmReport/B2BtransferReport',
    B2BInvoiceReport:'/api/CrmReport/B2BInvoiceReportGetCsvLink',


    //Manifest Create
    ManifestCreateHeader: '/api/outbound/Manifest/ManifestCreateHeader',
    ManifestScanAwbInvoice: '/api/outbound/Manifest/ManifestScanAwbInvoice',
    ManifestUpdateWeight: '/api/outbound/Manifest/ManifestUpdateWeight',
    ManifestComplete: '/api/outbound/Manifest/ManifestComplete',
    ManifestDeleteDocument: '/api/outbound/Manifest/ManifestDeleteDocument',
    ManifestList: '/api/outbound/Manifest/ManifestGet',
    ManifestPDF: '/api/outbound/Manifest/ManifestReport?manifest_no=',
    ManifestExcel: '/api/outbound/Manifest/ManifestReportCSVGet?manifest_no=',
    ManifestReportGetFromEasyEcom: '/api/outbound/Manifest/ManifestReportGetFromEasyEcom?manifest_no=',
    ManifestReportGetFromVinculum: '/api/outbound/Manifest/ManifestReportGetFromVinculum?manifest_no=',

    //Manifest Return
    ManifestReturnCreateHeader: '/api/returns/ReturnManifest/ReturnManifestCreateHeader',
    ManifestReturnScanAwbInvoice: '/api/returns/ReturnManifest/ReturnManifestScanAwbInvoice',
    ManifestReturnComplete: '/api/returns/ReturnManifest/ReturnManifestComplete',
    ReturnManifestDeleteDocument: '/api/returns/ReturnManifest/ReturnManifestDeleteAwbInvoice',
    DiscardDocument: '/api/returns/ReturnManifest/ReturnManifestDeleteDocument',

    //retun request create cr
    InvoiceLinesReturnMark: '/api/returns/ReturnGRN/InvoiceLinesReturnMark',
    Return_RequestComplete: '/api/returns/ReturnGRN/Return_RequestComplete',
    ReturnGRNGetCustomer: '/api/returns/ReturnGRN/ReturnGRNGetCustomer',
    CancelSaleOrderRRPOrder: '/api/SaleOrder/CancelSaleOrderRRPOrder',
    ReturnRequestUploader: '/api/returns/ReturnGRN/ReturnRequestUploader',
    GetReturnRequestUploaderHeaderData: '/api/returns/ReturnGRN/GetReturnRequestUploaderHeaderData',
    GetReturnRequestUploaderData: '/api/returns/ReturnGRN/GetReturnRequestUploaderData',
    ReturnScanLoopTag:'/api/returns/ReturnGRN/return_request_scan_barcode_with_looptag',
    //Return Request
    ReturnRequestHeaderGet: '/api/returns/ReturnGRN/ReturnRequestHeaderGet',
    ReturnRequestLineGet: '/api/returns/ReturnGRN/ReturnRequestLineGet?location_code=',

    //Transfer Order
    getTransferOrderHeader: '/api/transfer/TransferOrder/GetTransferOrderHeaderList',
    getTransferOrderLines: '/api/transfer/TransferOrder/TransferOrderGet/sale_order_id?sale_order_id=',
    getInvoiceHeaderTo: '/api/transfer/TransferOrder/InvoiceTransferHeaderGet',
    getTOInvoiceLines: '/api/transfer/TransferOrder/InvoicedTransferReportGet',
    getTOInvoiceReport: '/api/outbound/OutboundQualityCheck/Report_TO?order_no=',
    TransferMarkDispatch: '/api/outbound/OutboundQualityCheck/TransferMarkDispatch',

    GetTransferReturnPostedData: '/api/returns/ReturnGRN/GetTransferReturnPostedData',

    GetTransferReturnPostedLineData: '/api/returns/ReturnGRN/GetTransferReturnPostedLineData',
    cancelEWAYTO:'/api/outbound/IRNEwayBill/CancelEwayBill_TO',
    InvoiceReport_TO: '/api/outbound/OutboundQualityCheck/InvoiceReport_TO?flag=',


    //b2b Transfer
    B2bTransferList: '/api/transfer/TransferOrder/GetTransferB2BOrderHeaderList',
    B2bInvoiceReport: '/api/transfer/TransferOrder/InvoiceReport?sale_no=',
    B2bChallanReport: '/api/transfer/TransferOrder/ChallanReport?sale_no=',
    TransferB2BComplete: '/api/transfer/TransferOrder/TransferB2BComplete',
    B2bShortageGet:'/api/transfer/TransferOrder/TransferB2BCheckForShortageGet',
    B2bShortageLineGet:'/api/transfer/TransferOrder/TransferB2BHeaderShortageGet',
    B2bShortageLineCreate:'/api/transfer/TransferOrder/TransferB2BShortageCreate',
    B2bShortageLineDelete:'/api/transfer/TransferOrder/TransferB2BHeaderShortageDelete',
    B2bShoratgeItemGet:'/api/transfer/TransferOrder/TransferB2BCheckForShortageItemBinGet',
    B2bShortageComplete:'/api/transfer/TransferOrder/TransferB2BHeaderShortageComplete',

    generateTOIRN: '/api/outbound/IRNEwayBill/GenerateIRN_TO',
    cancelTOIRN: '/api/outbound/IRNEwayBill/CancelIRN_TO',
    getTOIRNcancelReason: '/api/outbound/IRNEwayBill/EInvoiceIRNCancelMstGetIRN_TO',

    generateTOEwayBill: '/api/outbound/IRNEwayBill/EwayBillGenerate_TO',


    EInvoiceEwayBillTranspoterGet:'/api/outbound/IRNEwayBill/EInvoiceEwayBillTranspoterGet',




    //  Reship Transfer Order
    GetTransferReturnData: '/api/returns/ReturnGRN/GetTransferReturnData',
    createTOReturnGRN: '/api/returns/ReturnGRN/TransferGRNCreateHeader',
    TOReturnGRNComplete: '/api/returns/ReturnGRN/TransferGRNComplete',

    //  GRN No List
    ReturnGRNHeaderGet: '/api/returns/ReturnGRN/ReturnGRNHeaderGet',
    ReturnGRNHeaderLineGet: '/api/returns/ReturnGRN/ReturnGRNHeaderLineGet?grn_no=',
    ReturnGenerateIRN: '/api/returns/ReturnIRNEwayBill/GenerateIRN',
    ReturnCancelIRN: '/api/returns/ReturnIRNEwayBill/CancelIRN',

    //  Upload Dispatch
    DeliveryUploader: '/api/outbound/Manifest/DeliveryUploader',
    DispatchedUploaderHeaderGet: '/api/outbound/Manifest/DispatchedUploaderHeaderGet?location_code=',
    DispatchedUploaderLineGetLogic: '/api/outbound/Manifest/DispatchedUploaderLineGetLogic?delivery_no=',

    //Serial History
    SerialHistory: '/api/Reports/SerialHistory?serial_no=',

    //Scrap
    createScrapHeader: '/api/inbound/Putaway/ScrapSale/ScrapSaleHeaderCreate',
    scanScrapSerial: '/api/inbound/Putaway/ScrapSale/ScrapSaleBoxSerialScan',
    discardScrapHeader: '/api/inbound/Putaway/ScrapSale/ScrapSaleDiscardDocument',
    completeScrapHeader: '/api/inbound/Putaway/ScrapSale/ScrapSaleComplete',
    getScrapHeader: '/api/inbound/Putaway/ScrapSale/ScrapSaleList',

    //  Bin to Bin Reclass
    verifyBin: '/api/inbound/ReclassPutaway/BinToBinVerifyBin',
    binToBinComplete: '/api/inbound/ReclassPutaway/BinToBinReclassComplete',
    binReclassList: '/api/inbound/ReclassPutaway/BinToBinReclassList',

    //purchase return bad process
    TransferPurchaseBadGet: '/api/transfer/TransferOrder/TransferPurchaseBadGet',
    TransferB2BBadGet: '/api/transfer/TransferOrder/TransferB2BCreateGet',
    TransferPurchaseHeaderCreate: '/api/transfer/TransferOrder/TransferPurchaseHeaderCreate',
    TransferPurchaseReturnDiscardDocument: '/api/transfer/TransferOrder/TransferPurchaseReturnDiscardDocument',
    TransferPurchaseReturnScanBoxSerial: '/api/transfer/TransferOrder/TransferPurchaseReturnScanBoxSerial',
    TransferPurchaseReturnComplete: '/api/transfer/TransferOrder/TransferPurchaseReturnComplete',
    ManualBoxHeaderCreate: '/api/inbound/Putaway/Adjustment/ManualBoxHeaderCreate',
    ManualBoxScanBoxSerial: '/api/inbound/Putaway/Adjustment/ManualBoxScanBoxSerial',
    ManualBoxDiscardDocument: '/api/inbound/Putaway/Adjustment/ManualBoxDiscardDocument',
    ManualBoxDocumentComplete: '/api/inbound/Putaway/Adjustment/ManualBoxDocumentComplete',
    ManualBoxList: '/api/inbound/Putaway/Adjustment/ManualBoxList',
  };


  constructor(private httpClient: HttpClient,
    private sessionManageMent: SessionManageMent,
    private _signalR: SignalR,
  ) {

  }

  check()
  {
    // let url ='https://beautydev.pristinefulfil.com/api/location/locationlist'
    let url ='https://beautydev.pristinefulfil.com/api/Location/GetLocationList'
    return this.httpClient.get(url)
  }

  //
  // get getHTTPHeader(): any {
  //   return {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json'
  //     })
  //   };
  // }

  get getHTTPHeader(): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.sessionManageMent.getAuthToken
      })
    };
  }
  // getHTTPHeaderAuth(token: string): any {
  //   return {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': 'Bearer ' + token
  //     })
  //   };
  // }

  // post data to server
  async Post(path: string, jsondata: any): Promise<any> {
    try {
      path = this.globalurl + path;
      var headers = this.getHTTPHeader;
      return await new Promise<any>((resolve, reject) => {
        this.httpClient.post<any>(path, JSON.stringify(jsondata), headers).toPromise()
          .then(result => resolve(result), error => {
            if (error.status == 401 && error.error.condition.toLowerCase() == "false") {
              this._signalR.stopSignalRConnection();
              localStorage.clear();
            }
            reject({
              condition: 'False',
              message: error.message
            })
          }).catch(err => reject({ condition: 'False', message: err.message }));
      });

    } catch (e) {
      return new Promise<any>((resolve) => {
        resolve({ condition: 'False', message: e.message })
      });
    }
  }

  // get data to the server
  async Get(path: string): Promise<any> {
    try {
      path = this.globalurl + path;
      var headers = this.getHTTPHeader;
      return await new Promise<any>((resolve, reject) => {
        this.httpClient.get<any>(path, headers).toPromise()
          .then(result => resolve(result), error => {
            if (error.status == 401 && error.error.condition.toLowerCase() == "false") {
              this._signalR.stopSignalRConnection();
              localStorage.clear();
            }
            reject({
              condition: 'False',
              message: error.message
            })
          }).catch(err => reject({ condition: 'False', message: err.message }));
      });
    } catch (e) {
      return new Promise<any>((resolve) => {
        resolve({ condition: 'False', message: e.message })
      });
    }
  }


  getImageSrc(url: string) {
    try {
      if (url.includes('imageNotFound.png')) {
        return url;
      } else {
        return this.globalurl + '/' + url;
      }
    } catch (e) {
      return '';
    }
  }

  // For formdata
  async PostFormData(path: string, formdata: any): Promise<any> {
    try {
      path = this.globalurl + path;
      return await new Promise<any>((resolve, reject) => {
        this.httpClient.post<any>(path, formdata).toPromise()
          .then(result => resolve(result), error => reject({
            condition: 'false',
            message: error.message
          })).catch(error => reject({
            condition: 'false',
            message: error.message
          }))
      })

    } catch (e) {
      return new Promise<any>((resolve) => {
        resolve({ condition: 'false', message: e.message })
      })
    }
  }

  // post data to server and get two type of response
  Post_Data_GetFile(path: string, jsondata: any) {
    path = this.globalurl + path;
    const request = new HttpRequest('POST', path, jsondata, {
      responseType: 'blob',
      reportProgress: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json').append('Authorization', this.sessionManageMent.getAuthToken)
    });
    return this.httpClient.request(request);
  }

  //dowling blob into pdf open into print with using the get api

  Get_Data_With_DownloadStatus_GetFile(path: string) {
    path = this.globalurl + path;
    const request = new HttpRequest('GET', path, {
      responseType: 'blob',
      reportProgress: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json').append('Authorization', this.sessionManageMent.getAuthToken)
    });
    return this.httpClient.request(request);
  }

  Get_Data_With_DownloadStatus_GetFile_1(path: string) {
    const request = new HttpRequest('GET', path, {
      responseType: 'blob',
      reportProgress: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json').append('Authorization', this.sessionManageMent.getAuthToken)
    });
    return this.httpClient.request(request);
  }

  Get_Data_With_DownloadStatus_GetFile_binary(path: string) {
    const request = new HttpRequest('GET', path, {
      responseType: 'blob',
      reportProgress: true,
      headers: new HttpHeaders().append('Content-Type', 'binary/octet-stream').append('Authorization', this.sessionManageMent.getAuthToken)
    });
    return this.httpClient.request(request);
  }


  // when we have to download and open pdf in the iframe and use post api with json

  Post_Data_With_DownloadStatus_GetFile(path: string, jsondata: any) {
    path = this.globalurl + path;
    const request = new HttpRequest('POST', path, jsondata, {
      responseType: 'blob',
      reportProgress: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
    return this.httpClient.request(request);
  }

  blobToString(b) {
    var urldata, x;
    urldata = URL.createObjectURL(b);
    x = new XMLHttpRequest();
    x.open('GET', urldata, false); // although sync, you're not fetching over internet
    x.send();
    URL.revokeObjectURL(urldata);
    return x.responseText;
  }

}
